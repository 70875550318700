const TestimonialItem = ({ name, type = "Paciente", text }) => {
  return (
    <div className="slider-item">
      <div className="testimonial-bx">
        <div className="testimonial-content">
          <p>{text}</p>
        </div>
        <div className="client-info">
          <h5 className="name">{name}</h5>
          <p>{type}</p>
        </div>
        <div className="quote-icon">
          <i className="fas fa-quote-left"></i>
        </div>
      </div>
    </div>
  );
};

export default TestimonialItem;
