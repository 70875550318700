import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Import Images
import testShape from "../../images/testimonials/shape.png";
import plusOrange from "../../images/shap/plus-orange.png";
import squareBlue from "../../images/shap/square-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import circleOrange2 from "../../images/shap/circle-orange-2.png";

/**Custom imports */
// import { ImagesURLs } from "../../constants/AssetStrings";
import TestimonialItem from "./testimonial-item";
import { teamMembers } from "../../constants/TeamMembers";

class testimonialSection extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1200,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
    };

    return (
      <>
        <section className="section-area section-sp3 testimonial-wraper">
          <div className="container">
            <div className="heading-bx text-center">
              <h6 className="title-ext text-secondary">Testimonios</h6>
              <h2 className="title m-b0">
                Conoce lo que opinan <br /> nuestros pacientes
              </h2>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 text-center">
                <div className="thumb-wraper">
                  <img className="bg-img" src={testShape} alt="" />
                  <ul>
                    {teamMembers.map((member, index) => (
                      <li data-member="1" key={index}>
                        <Link to="#">
                          <img src={member.url} alt="" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <Slider {...settings} className="testimonial-slide">
                  <TestimonialItem
                    text={
                      "Excelente médico, todo muy bien explicado y es muy dedicada a sus pacientes. La Doctora está muy preparada en su especialidad."
                    }
                    name={"Jessica Valdés"}
                  />
                  <TestimonialItem
                    text={
                      "Extraordinaria Dra te explica con mucho detalle todo te revisa a conciencia. A cambiado mi forma de vida para mucho mejor desde mi primer cita super recomendable"
                    }
                    name={"Alfredo"}
                  />
                  <TestimonialItem
                    text={
                      "Estoy muy agradecida por su atención, analizó todos mis estudios y me explicó sobre mis medicamentos para la diabetes, en muchos años nunca nadie me había explicado tan bien. Gracias"
                    }
                    name={"Raquel CM"}
                  />
                  <TestimonialItem
                    text={
                      "La mejor Dra q puedo tener , después de años de batallar, ella ha Sido muy certera con el tratamiento y diagnóstico, la recomiendo ampliamente, cambio mi vida."
                    }
                    name={"Paulina"}
                  />
                  <TestimonialItem
                    text={
                      "Desde agosto con TSH altísimo!! Hoy controlada de hipotiroidismo. Mejorando y sanando gracias a la atención e indicaciones médicas de la Dra. Caro. Es una ser humano amorosa, dedicada, realmente preocupada por sus pacientes y lo mejor del mundo es que tiene MUCHA EXPERIENCIA Y CONOCIMIENTOS todo te lo explica perfectamente. Mil gracias."
                    }
                    name={"Jaqui Meléndez"}
                  />
                  <TestimonialItem
                    text={
                      "Después de haber tenido una experiencia previa con otro endocrinólogo, creo que encontré lo que buscaba, una doctora que se preocupa por sus pacientes, es detallada en sus explicaciones, calidez en la atención médica y muy acertada en sus diagnósticos y tratamientos. En conclusión una excelente doctora la recomiendo ampliamente."
                    }
                    name={"Alejandro"}
                  />
                </Slider>
              </div>
            </div>
          </div>
          <img className="pt-img1 animate1" src={plusOrange} alt="" />
          <img className="pt-img2 animate2" src={squareBlue} alt="" />
          <img className="pt-img3 animate3" src={circleDots} alt="" />
          <img className="pt-img4 animate4" src={circleOrange2} alt="" />
        </section>
      </>
    );
  }
}

export default testimonialSection;
