import React, { Component } from "react";

// Import Images

import ptImg1 from "../../images/shap/trangle-orange.png";
import ptImg2 from "../../images/shap/square-dots-orange.png";
import ptImg3 from "../../images/shap/line-circle-blue.png";
import ptImg5 from "../../images/shap/circle-dots.png";

/**Custom Imports */
import { teamMembers } from "../../constants/TeamMembers";
import TeamItem from "./team-item";

class TeamSection extends Component {
  render() {
    return (
      <>
        <section className="section-area section-sp3 team-wraper">
          <div className="container">
            <div className="heading-bx text-center">
              <h6 className="title-ext text-secondary">Especialistas</h6>
              <h2 className="title">Conoce al equipo clínico y quirúrgico</h2>
            </div>
            <div className="row justify-content-center">
              {teamMembers.map((member, index) => (
                <TeamItem
                  key={index}
                  imageSrc={member.url}
                  name={member.name}
                  speciality={member.speciality}
                  instagram={member?.instagram}
                />
              ))}
            </div>
          </div>
          <img className="pt-img1 animate1" src={ptImg1} alt="" />
          <img className="pt-img2 animate2" src={ptImg2} alt="" />
          <img className="pt-img3 animate-rotate" src={ptImg3} alt="" />
          <img className="pt-img5 animate-wave" src={ptImg5} alt="" />
        </section>
      </>
    );
  }
}

export default TeamSection;
