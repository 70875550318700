import React, { Component } from "react";
// import { Link } from "react-router-dom";

// Import Images
import bg1 from "../../images/background/line-bg1.png";
// import ptImg1 from "../../images/shap/circle-orange.png";
// import ptImg2 from "../../images/shap/plus-orange.png";
// import ptImg3 from "../../images/shap/circle-dots.png";

/* 
TO DO quitar los botones de siguiente y comenzar un stepper
Pasas por agendar cita, luego historia clinica y por ultimo pantalla de successs.

TO DO agregar boton unico de comenzar proceso.
 */

class aboutSection extends Component {
  render() {
    return (
      <>
        <section
          className="section-area section-sp5 work-area"
          style={{
            backgroundImage: "url(" + bg1 + ")",
            backgroundRepeat: " no-repeat",
            backgroundPosition: "center",
            backgroundSize: "100%",
          }}
        >
          <div className="container-sm">
            <div className="heading-bx text-center">
              <h6 className="title-ext text-secondary">
                Métodología de Trabajo
              </h6>
              <h2 className="title">Cómo trabajamos?</h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">01</div>
                  <div className="work-content">
                    <h5 className="title text-secondary mb-10">
                      Agenda una cita
                    </h5>
                    <p>
                      Selecciona la especialidad con la que te gustaría agendar
                      consulta: Endocrinología, Nutrición o ambas.
                    </p>
                  </div>
                  {/* <Link to="/booking" className="btn btn-primary light">
                    Ver más <i className="btn-icon-bx fas fa-chevron-right"></i>
                  </Link> */}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">02</div>
                  <div className="work-content">
                    <h5 className="title text-secondary mb-10">
                      Déjanos conocerte
                    </h5>
                    <p>
                      Utiliza nuestro sistema digital de captura de historia
                      clínica.
                    </p>
                  </div>
                  {/* <Link to="/services" className="btn btn-primary light">
                    Ver más <i className="btn-icon-bx fas fa-chevron-right"></i>
                  </Link> */}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">03</div>
                  <div className="work-content">
                    <h5 className="title text-secondary mb-10">
                      Inicia tratamiento personalizado
                    </h5>
                    <p>
                      Tenemos varias opciones para ti, nuestros tratamientos son
                      multidisciplinarios y diseñados para tus necesidades
                      específicas.
                    </p>
                  </div>
                  {/* <Link to="/contact-us" className="btn btn-primary light">
                    Ver más <i className="btn-icon-bx fas fa-chevron-right"></i>
                  </Link> */}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">04</div>
                  <div className="work-content">
                    <h5 className="title text-secondary mb-10">
                      Comprende tu tratamiento
                    </h5>
                    <p>
                      La comunicación y entendimiento es fundamental para tu mejoría clínica.
                    </p>
                  </div>
                  {/* <Link to="/contact-us" className="btn btn-primary light">
                    Ver más <i className="btn-icon-bx fas fa-chevron-right"></i>
                  </Link> */}
                </div>
              </div>
            </div>
            
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: 150,
              }}
              className="button-row"
            >
              <Link to="/booking" className="btn btn-primary light">
                Agenda tu cita{" "}
                <i className="btn-icon-bx fas fa-chevron-right"></i>
              </Link>
            </div> */}
          </div>
          {/* <img className="pt-img1 animate1" src={ptImg1} alt="" />
          <img className="pt-img2 animate2" src={ptImg2} alt="" /> */}
          {/* <img className="pt-img3 animate3" src={ptImg3} alt="" /> */}
        </section>
      </>
    );
  }
}

export default aboutSection;
