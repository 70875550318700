import React from 'react'

import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";

const ContactBanner = () =>{
    return(
        <section className="section-area section-sp1">
            <div className="container">
                <div className="row">
                    <div className="row justify-content-center">
                    <div className="heading-bx text-center">
                        <h6 className="title-ext text-secondary">
                            Datos de Contacto
                        </h6>
                        <h2 className="title">Agenda tu cita con nosotros</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 mb-30">
                        <div className="feature-container feature-bx4 feature4">
                            <div className="icon-md feature-icon">
                                <img src={icon1} alt=""/>
                            </div>
                            <div className="icon-content">
                                <h5 className="ttr-title">Teléfono</h5>
                                <h6>442 622 2564</h6>
                                {/* <p>+002 3424 44 00</p> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-6 mb-30">
                        <div className="feature-container feature-bx4 feature2">
                            <div className="icon-md feature-icon">
                                <img src={icon2} alt=""/>
                            </div>
                            <div className="icon-content">
                                <h5 className="ttr-title">Hospital Tec 100 / H+</h5>
                                <h6>Privada Ignacio Zaragoza 16, torre 3 consultorio 905</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-6 mb-30">
                        <div className="feature-container feature-bx4 feature3">
                            <div className="icon-md feature-icon">
                                <img src={icon3} alt=""/>
                            </div>
                            <div className="icon-content">
                                <h5 className="ttr-title">Correos electrónicos</h5>
                                <p> <strong style={{fontWeight:'bold'}}>carolina.lara@tiroidesmanzana.com </strong>- Dra. Carolina Lara Sánchez - Endocrinología</p>
                                <p> <strong style={{fontWeight:'bold'}}>liliana.sanroman@tiroidesmanzana.com </strong> - Nut. Lliliana San Román - Nutrición </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default ContactBanner