const TeamItem = ({ imageSrc = "", name = "", speciality = "", instagram }) => {
  return (
    <>
      <div className="col-lg-4 col-sm-6 mb-30">
        <div className="team-member" style={{ height: "100%" }}>
          <div className="team-media">
            <img src={imageSrc} alt="" />
          </div>
          <div className="team-info">
            <div className="team-info-comntent">
              <h4 className="title">{name} </h4>
              <span className="text-secondary">{speciality}</span>
            </div>
            <ul className="social-media">
              {instagram && (
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              )}
              {/* <li>
                <a rel="noreferrer" target="_blank" href="https://twitter.com/">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
               */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamItem;
