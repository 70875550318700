import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import Images
import aboutThumb1 from "../../images/about/pic-1.jpg";
import aboutThumb2 from "../../images/about/pic-2.jpg";
import aboutThumb3 from "../../images/about/pic-3.jpg";
// import ptImg1 from "../../images/shap/wave-orange.png";
import ptImg2 from "../../images/shap/circle-small-blue.png";
// import ptImg4 from "../../images/shap/square-dots-orange.png";
import ptImg5 from "../../images/shap/square-blue.png";

class aboutSection extends Component {
  render() {
    return (
      <>
        <section className="section-sp1 about-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-30">
                <div className="about-thumb-area">
                  <ul>
                    <li>
                      <img className="about-thumb1" src={aboutThumb1} alt="" />
                    </li>
                    <li>
                      <img className="about-thumb2" src={aboutThumb2} alt="" />
                    </li>
                    <li>
                      <img className="about-thumb3" src={aboutThumb3} alt="" />
                    </li>
                    <li>
                      <div className="exp-bx" style={{ fontSize: 70 }}>
                        +500
                        <span style={{ padding: "0 30px", lineHeight: 2 }}>
                          Pacientes satisfechos
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="heading-bx">
                  <h6 className="title-ext text-secondary">Conócenos</h6>
                  <h2 className="title">Investigación aplicada en tu salud</h2>
                  <p>
                    Centro médico basado en abordajes científicos para la
                    mejoría de tu metabolismo y tus hormonas.
                  </p>
                </div>

                <Link to="/about-us" className="btn btn-primary shadow">
                  Leer más
                </Link>
              </div>
            </div>
          </div>
          {/* <img className="pt-img1 animate-wave" src={ptImg1} alt="" /> */}
          <img className="pt-img2 animate2" src={ptImg2} alt="" />
          <img className="pt-img3 animate-rotate" src={ptImg5} alt="" />
          {/* <img className="pt-img4 animate-wave" src={ptImg4} alt="" /> */}
          {/* <img className="pt-img5 animate2" src={ptImg5} alt="" /> */}
        </section>
      </>
    );
  }
}

export default aboutSection;
