export const services = [
  {
    id: "endocrinologia",
    title: "Endocrinología",
    description:
      "Atención de problemas hormonales como tiroides, diabetes, menopausia, obesidad, osteoporosis, hipófisis, cortisol y más",
  },
  {
    id: "nutricion",
    title: "Nutrición",
    description:
      "Cuidado de alteraciones metabólicas y mejora de tus estrategias alimenticias con base en tu estilo de vida actual",
  },
  {
    id: "oftalmologia",
    title: "Oftalmología",
    description:
      "Diagnóstico y tratamiento de todas las enfermedades oculares, incluyendo cirugías y ajuste de graduación visual personalizada",
  },
  {
    id: "cirugia",
    title: "Cirugía Bariátrica",
    description:
      "Apoyo en el tratamiento de alteraciones metabólicas mayores y obesidad de difícil control. Seguimiento en conjunto con especialistas clínicos",
  },
  {
    id: "psicologia",
    title: "Psicología",
    description:
      "Cuidado de tu salud mental enfocado a estrategias para el control de tus emociones y herramientas para mejorar tu control metabólico y vida personal",
  },
];
