import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

// Images
// import logo from "../../images/tiroides-manzana-logo.png";

const logoSquare =
  "https://firebasestorage.googleapis.com/v0/b/dralarasanchez.appspot.com/o/logos%2Ftiroides-manzana-logo.png?alt=media&token=932262d4-881e-4af0-99ff-e3338268441f";

const Header = () => {
  const [hamState, setHamState] = useState(false);

  const toggleHam = () => setHamState(!hamState);

  return (
    <>
      <header className="header header-transparent rs-nav">
        <Sticky enabled={true} className="sticky-header navbar-expand-lg">
          <div className="menu-bar clearfix">
            <div className="container-fluid clearfix">
              <div className="menu-logo logo-dark">
                <Link to="/">
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/dralarasanchez.appspot.com/o/logos%2Ftiroides-manzana-logo-horizontal.png?alt=media&token=1edc4ad1-fb62-4dae-a88e-d284b55354b4"
                    }
                    alt="Logo Clinica Tiroides Manzana"
                  />
                </Link>
              </div>
              <button
                className="navbar-toggler collapsed menuicon justify-content-end"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#menuDropdown"
                aria-controls="menuDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleHam}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="secondary-menu">
                <ul>
                  {/* <li className="search-btn">
                    <button
                      id="quikSearchBtn"
                      type="button"
                      className="btn-link"
                    >
                      <i className="las la-search"></i>
                    </button>
                  </li> */}
                  <li className="num-bx">
                    <a href="tel:(+52)442 622 2564">
                      <i className="fas fa-phone-alt"></i> 442 622 2564
                    </a>
                  </li>
                  <li className="btn-area">
                    <Link to="/contact-us" className="btn btn-primary shadow">
                      AGENDA UNA CITA{" "}
                      <i className="btn-icon-bx fas fa-chevron-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className={`menu-links navbar-collapse collapse justify-content-end ${
                  hamState && "show"
                } `}
                id="menuDropdown"
              >
                <div className="menu-logo">
                  <Link to="/">
                    <img src={logoSquare} alt="" />
                  </Link>
                </div>
                <ul className="nav navbar-nav">
                  <li className="active">
                    <Link to="/">Inicio</Link>
                  </li>
                  <li>
                    <Link to="/about-us">
                      <span>Quiénes Somos</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/faq">
                      <span>FAQ's</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/services">
                      <span>Servicios</span>
                    </Link>
                  </li>
                  <li style={{ marginTop: 100 }} className="btn-area d-lg-none">
                    <Link to="/contact-us" className="btn btn-primary shadow">
                      AGENDA UNA CITA{" "}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/blog-grid">
                      <span>Blog</span>
                    </Link>
                  </li> */}
                </ul>
                {/* <ul className="social-media" style={{ paddingBottom: 100 }}>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.google.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-google"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul> */}
                <div className="menu-close" id="menuClose" onClick={toggleHam}>
                  <i className="ti-close"></i>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
        {/* <div className="nav-search-bar">
          <form>
            <input
              type="text"
              className="form-control"
              placeholder="Type to search"
            />
            <span>
              <i className="ti-search"></i>
            </span>
          </form>
          <span id="searchRemove">
            <i className="ti-close"></i>
          </span>
        </div> */}
      </header>
    </>
  );
};

export default Header;
