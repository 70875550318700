export const teamMembers = [
  {
    name: "Dra. Carolina Lara",
    speciality: "Endocrinología",
    url: "https://firebasestorage.googleapis.com/v0/b/dralarasanchez.appspot.com/o/assets%2Fdoctors%2Fendocrinologia.png?alt=media&token=c29ab0c9-c326-4433-a3f1-9527fdd0662c",
    instagram: "https://instagram.com/endocrino.dra.lara?igshid=YmMyMTA2M2Y=",
  },
  {
    name: "Nut. Liliana San Román",
    speciality: "Nutrición",
    url: "https://firebasestorage.googleapis.com/v0/b/dralarasanchez.appspot.com/o/assets%2Fdoctors%2Fnutricion.png?alt=media&token=9244b877-fdd9-4aaf-b8a3-7d6208b90ee4",
    instagram:
      "https://instagram.com/nutricion.lilianasanroman?igshid=YmMyMTA2M2Y=",
  },
  {
    name: "Dr. Rodrigo Conde",
    speciality: "Cirugía",
    url: "https://firebasestorage.googleapis.com/v0/b/dralarasanchez.appspot.com/o/assets%2Fdoctors%2Fcirugia.png?alt=media&token=0f23296b-e2c8-477b-835a-b567ba70704f",
    instagram: "https://instagram.com/obesidadcero.mx?igshid=YmMyMTA2M2Y=",
  },
  {
    name: "Dr. Hector Mendoza",
    speciality: "Oftalmología",
    url: "https://firebasestorage.googleapis.com/v0/b/dralarasanchez.appspot.com/o/assets%2Fdoctors%2Fophthalmology01.png?alt=media&token=ea9ce40f-122a-417d-aa60-af23955c1dbb",
    instagram:
      "https://instagram.com/vision_central_oftalmo?igshid=YmMyMTA2M2Y=",
  },
  {
    name: "Dra. Paola Brito",
    speciality: "Oftalmología",
    url: "https://firebasestorage.googleapis.com/v0/b/dralarasanchez.appspot.com/o/assets%2Fdoctors%2Fophthalmology02.jpeg?alt=media&token=9a8c1134-80d5-414e-ae7e-4ea901f853af",
    instagram:
      "https://instagram.com/vision_central_oftalmo?igshid=YmMyMTA2M2Y=",
  },
  {
    name: "Psic. Olga Fajardo",
    speciality: "Psicología",
    url: "https://firebasestorage.googleapis.com/v0/b/dralarasanchez.appspot.com/o/assets%2Fdoctors%2Fpsicologia.png?alt=media&token=0fd57b6b-9e01-4ca9-8fcb-90115ee1bcdb",
    instagram: "https://instagram.com/psicolgafajardo?igshid=YmMyMTA2M2Y=",
  },
];
